var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
      },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "search-form search__autocomplete" },
      [
        _c(
          _vm.customSearch ? "div" : "form",
          {
            tag: "component",
            staticClass: "--form",
            attrs: { action: "/search", method: "get" },
          },
          [
            _c("div", { staticClass: "form__input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputQuery,
                    expression: "inputQuery",
                  },
                ],
                attrs: {
                  id: _vm.customSearch ? "customQuery" : "query",
                  name: "query",
                  placeholder: _vm.$t("autocomplete-placeholder"),
                  type: "search",
                  autocomplete: "off",
                },
                domProps: { value: _vm.inputQuery },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.inputQuery = $event.target.value
                    },
                    function ($event) {
                      return _vm.fetchAutocompleteData()
                    },
                  ],
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.autocompleteLoading,
                    expression: "autocompleteLoading",
                  },
                ],
                staticClass: "--loading",
              },
              [_c("span", { staticClass: "--spinner" })]
            ),
            _vm._v(" "),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.autocompleteRemove && !_vm.autocompleteLoading,
                  expression: "autocompleteRemove && ! autocompleteLoading",
                },
              ],
              staticClass: "--remove",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.clearSearchInput()
                },
              },
            }),
            _vm._v(" "),
            _c("button", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.autocompleteLoading,
                  expression: "! autocompleteLoading",
                },
              ],
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showAutocomplete,
                expression: "showAutocomplete",
              },
            ],
            staticClass: "search__autocomplete_dropdown",
          },
          [
            _c("div", { staticClass: "--products" }, [
              _vm.autocompleteData["products"].length
                ? _c(
                    "div",
                    { staticClass: "product__result" },
                    [
                      _vm._l(
                        _vm.autocompleteData["products"],
                        function (product, index) {
                          return index < 5 || _vm.customSearch
                            ? [
                                _vm.customSearch
                                  ? _c(
                                      "label",
                                      {
                                        class: {
                                          "--active": _vm.isSelectedProduct(
                                            product.entity_id
                                          ),
                                        },
                                        attrs: {
                                          for: `p-${product.entity_id}`,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "image__holder" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: _vm.imageSrc(product),
                                                width: "85",
                                                height: "85",
                                                alt: product.name,
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "--info" }, [
                                          _c(
                                            "span",
                                            { staticClass: "--title" },
                                            [
                                              _vm._v(
                                                _vm._s(product["ec_brand"]) +
                                                  " " +
                                                  _vm._s(product.name)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "--model" },
                                            [
                                              _vm._v(
                                                _vm._s(product["ec_modelid"])
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          product["show_price"]
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "price__holder",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "price__final-price",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          product[
                                                            "final_price_incl_tax"
                                                          ].priceFormat(
                                                            _vm.locale
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "--checkbox" },
                                          [
                                            _c("input", {
                                              attrs: {
                                                type: "checkbox",
                                                id: `p-${product.entity_id}`,
                                              },
                                              domProps: {
                                                checked: _vm.isSelectedProduct(
                                                  product.entity_id
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.addProduct(product)
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "a",
                                      { attrs: { href: product["url_key"] } },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "image__holder" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: _vm.imageSrc(product),
                                                width: "85",
                                                height: "85",
                                                alt: product.name,
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "--info" }, [
                                          _c(
                                            "span",
                                            { staticClass: "--title" },
                                            [
                                              _vm._v(
                                                _vm._s(product["ec_brand"]) +
                                                  " " +
                                                  _vm._s(product.name)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "--model" },
                                            [
                                              _vm._v(
                                                _vm._s(product["ec_modelid"])
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          product["show_price"]
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "price__holder",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "price__final-price",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          product[
                                                            "final_price_incl_tax"
                                                          ].priceFormat(
                                                            _vm.locale
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    ),
                              ]
                            : _vm._e()
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.autocompleteData["products"].length > 5 && !_vm.customSearch
                ? _c("p", { staticClass: "more__results" }, [
                    _c(
                      "a",
                      { attrs: { href: `/search?query=${_vm.inputQuery}` } },
                      [_vm._v(_vm._s(_vm.$t("autocomplete-all-results")))]
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.customSearch
              ? _c("div", { staticClass: "--actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "--button",
                      attrs: { disabled: !_vm.getProductRows.length },
                      on: {
                        click: function ($event) {
                          return _vm.addProductsToQuickBuy()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("search")))]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.autocompleteData["pages"].length ||
            (_vm.autocompleteData["categories"].length && !_vm.customSearch)
              ? _c("div", { staticClass: "--extra" }, [
                  _vm.autocompleteData["categories"].length
                    ? _c(
                        "div",
                        { staticClass: "category__result" },
                        [
                          _c("h4", [
                            _vm._v(_vm._s(_vm.$t("autocomplete-categories"))),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.autocompleteData["categories"],
                            function (page, index) {
                              return index < 5
                                ? _c(
                                    "a",
                                    {
                                      key: index,
                                      attrs: { href: page["url_key"] },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(page["name"]) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e()
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.autocompleteData["pages"].length
                    ? _c(
                        "div",
                        { staticClass: "cms__result" },
                        [
                          _c("h4", [
                            _vm._v(_vm._s(_vm.$t("category-cms-results"))),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.autocompleteData["pages"],
                            function (page, index) {
                              return index < 5
                                ? _c(
                                    "a",
                                    {
                                      key: index,
                                      attrs: { href: page["url_key"] },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(page["title"]) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e()
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }